<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    :items="items"    
    :item-text="item => $t(`dashboard.data_type_enum.${item.text}`)"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: false }"
    @input="$emit('input', $event)"
  />
</template>

<script>
  import { DataType } from "./enums";

  export default {
    inheritAttrs: false,
    name: "DataTypePicker",
    props: {
      value: String
    },
    data: vm => ({
      items: vm.$helpers.fromEnumToArray(DataType)
    })
  };
</script>
